
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import AddNew from "@/components/operations/transfers/location/AddNew.vue";
import Update from "@/components/operations/transfers/location/Update.vue";
import {Actions} from "@/store/enums/StoreEnums";

import Swal from "sweetalert2/dist/sweetalert2.js";


export default defineComponent({
  name: "operation-transfer-locations",
  components: {
    AddNew,
    Update,
  },

  setup() {

    store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
    const myTransferLocations = computed(() => {
      return store.getters.transferLocationsList;
    })

    const deleteLocation = (ID) => {
      store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload1 = {
            ID: ID,
          }
          store.dispatch(Actions.TRANSFER_LOCATIONS_DELETE, payload1)
              .then(() => {
                store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getTransferLocationsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    };


    onMounted(() => {
      setCurrentPageBreadcrumbs("Transfer Bölgeleri", ["Operasyon"]);
    });

    const itemID = ref<number>(0);
    function setItems(ID) {
      itemID.value=ID;
    }

    return {
      itemID,
      setItems,
      myTransferLocations,
      deleteLocation,

    };
  },
});
