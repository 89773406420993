
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import {ErrorMessage, Field, Form} from "vee-validate";
import transferlocationtype from "@/core/data/transferlocationtype";

interface addLocations {
  locationTitle: string;
  locationType: string;

}

export default defineComponent({
  name: "operation-transfer-company-car-add",
  components: {
    Field,
    Form,
    ErrorMessage,
  },


  props: {
    locationID: String,
  },

  setup(props) {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);

    const addLocations = ref<addLocations>({
      locationTitle: "",
      locationType: "",
    });

    const validationSchema = Yup.object().shape({
      locationTitle: Yup.string().required("Doldurulması zorunlu"),
      locationType: Yup.string().required("Doldurulması zorunlu"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }


      const payload1 = {
        Title: addLocations.value.locationTitle,
        Type: addLocations.value.locationType,
      }


      store.dispatch(Actions.TRANSFER_LOCATIONS_INSERT, payload1)
          .then(() => {
            store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    }

    return {
      submitButtonRef,
      addLocations,
      newRoomModalRef,
      validationSchema,
      submit,
      transferlocationtype,

    };
  },
});
