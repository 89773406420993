const AddTransferLocation = {
  "TYPES" : [
    {
      "Value" : 'LOCATION',
      "Title" : 'LOCATION',
    },
    {
      "Value" : 'AIRPORT',
      "Title" : 'AIRPORT',
    },
    ],
};
export default AddTransferLocation;
