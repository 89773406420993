
import {defineComponent,} from "vue";
import List from "@/components/operations/transfers/location/List.vue";




export default defineComponent({
  name: "operation-transfer-location",
  components: {
    List,
  },

});
